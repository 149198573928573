@import "../../shared/colors.scss";

.home {
  padding: 35px 0px;
  text-align: center;
}

.home__header {
  color: $white;
  font-size: 48px;
  margin: 0;
  text-shadow: 1px 1px 1px $black;
}

.home__image {
  display: block;
  margin: auto;
  width: 75%;
}
