@import "../../shared/responsive.scss";

.nav {
  background-color: rgba(255, 255, 255, 0.7);
  padding-top: 10px;
}

.nav__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.nav__image {
  width: 200px;
}

.nav__links {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  max-width: $breakpoint;
  padding: 0;
}

.nav__link {
  margin-left: 25px;
}

.nav__link--active {
  text-decoration: underline;
}
