@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600);
.container {
  margin: auto;
  width: 980px; }
  @media screen and (max-width: 1000px) {
    .container {
      width: 100%; } }

.nav {
  background-color: rgba(255, 255, 255, 0.7);
  padding-top: 10px; }

.nav__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 25px; }

.nav__image {
  width: 200px; }

.nav__links {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  max-width: 1000px;
  padding: 0; }

.nav__link {
  margin-left: 25px; }

.nav__link--active {
  text-decoration: underline; }

.social-links {
  display: flex;
  justify-content: center;
  margin: 20px 0; }

svg {
  height: 50px;
  width: 50px; }

.social-link {
  height: 50px;
  margin: 0 15px;
  width: 50px; }

.app {
  line-height: 1.4; }

.app__bg {
  background-image: url(/static/media/overman.5015c97a.jpg);
  background-repeat: no-repeat;
  background-size: cover; }
  @media screen and (max-width: 1000px) {
    .app__bg {
      background-position: center; } }

.app__description {
  color: #111;
  padding: 0 25px;
  text-align: center; }

.app__footer {
  border-top: 1px solid #999;
  color: #111;
  margin-top: 50px;
  padding: 30px 25px 0 25px;
  text-align: center; }

.app__header {
  color: #111;
  text-align: center; }

.home {
  padding: 35px 0px;
  text-align: center; }

.home__header {
  color: #fff;
  font-size: 48px;
  margin: 0;
  text-shadow: 1px 1px 1px #111; }

.home__image {
  display: block;
  margin: auto;
  width: 75%; }

a {
  text-decoration: none; }

body {
  font-family: "Josefin Sans", sans-serif;
  margin: 0;
  padding: 0; }

