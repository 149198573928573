@import "../../shared/responsive.scss";

.container {
  margin: auto;
  width: 980px;

  @include mobile {
    width: 100%;
  }
}
