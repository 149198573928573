@import "../../shared/colors.scss";
@import "../../shared/responsive.scss";
@import "../../shared/typography.scss";

.app {
  line-height: 1.4;
}

.app__bg {
  background-image: url("../../images/overman.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @include mobile {
    background-position: center;
  }
}

.app__description {
  color: $black;
  padding: 0 25px;
  text-align: center;
}

.app__footer {
  border-top: 1px solid $gray-99;
  color: $black;
  margin-top: 50px;
  padding: 30px 25px 0 25px;
  text-align: center;
}

.app__header {
  color: $black;
  text-align: center;
}
