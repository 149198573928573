.social-links {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

svg {
  height: 50px;
  width: 50px;
}

.social-link {
  height: 50px;
  margin: 0 15px;
  width: 50px;
}
