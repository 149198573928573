@import "./shared/typography.scss";

a {
  text-decoration: none;
}

body {
  font-family: $font-sans-serif;
  margin: 0;
  padding: 0;
}
